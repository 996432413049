import WOW from 'wow.js';

function initHomepage() {
  $('input,textarea').each((index, element) => {
    element.setAttribute('value', element.value);
  });

  $('input,textarea').change((e) => {
    e.target.setAttribute('value', e.target.value);
  });

  new WOW(
    {
      boxClass: 'wow',      // default
      animateClass: 'animated', // default
      offset: 0,          // default
      mobile: 0,       // default
      live: true        // default
    }
  ).init();

  $(".services").mouseover(function () {
    $("#serv").css("opacity", 0.5);
  });
  $(".services").mouseout(function () {
    $("#serv").css("opacity", 1);
  });

  $(".company").mouseover(function () {
    $("#comp").css("opacity", 0.5);
  });
  $(".company").mouseout(function () {
    $("#comp").css("opacity", 1);
  });

  $(".vacancies").mouseover(function () {
    $("#car").css("opacity", 0.5);
  });
  $(".vacancies").mouseout(function () {
    $("#car").css("opacity", 1);
  });

  $(".send_info").mouseover(function () {
    $("#con").css("opacity", 0.5);
  });
  $(".send_info").mouseout(function () {
    $("#con").css("opacity", 1);
  });

  $('.burger').click(() => {
    $('.burger span').toggleClass('active');
    $('.menu').toggleClass('animate');
  });

  // $(document).click(function (e) {
  //     const target = $(e.target);
  //     const langDropdowns = $('.header__lang');

  //     const isActive = langDropdowns.hasClass('header__lang_active');
  //     const isLangSelect = !!target.closest(langDropdowns).length;

  //     if (isLangSelect && !isActive) {
  //         langDropdowns.addClass('header__lang_active');
  //     } else {
  //         langDropdowns.removeClass('header__lang_active');
  //     }
  // });

  $('.header__lang').click(() => {
    $('.slider').toggleClass('active-slider');

    if ($('.switch-on').hasClass('active')) {
      $('.switch-on').removeClass('active');
      $('.switch-off').addClass('active');
    } else {
      $('.switch-off').removeClass('active');
      $('.switch-on').addClass('active');
    }
  });

  function showHeroImage() {
    const imageQty = 2;
    const imageIndex = parseInt(window.localStorage.getItem('heroIndex') || 0);

    $(`.hero_img-${imageIndex}`).addClass('show');
    window.localStorage.setItem('heroIndex', (imageIndex + 1) % imageQty);
  }
  showHeroImage();
}

window.initHomepage = initHomepage;

export default initHomepage;
