window.initQuestionsForm = (options = {
    route,
    locale
}) => {
    const submitForm = $('#questions-form');
    const submitButton = $('#questions-form-submit');
    const formErrorsHandler = FormErrorsHandler("questions-form", {
        errorBoxStyle: 'color: red; padding-top: 10px; display: flex; flex-direction: column;'
    });
    const messages = {
        en: {
            success: 'Thank you! Your data has been submitted.',
            error: 'Error! Please try again later',
        },
        uk: {
            success: 'Дякую! Дані було прийнято.',
            error: 'Помилка! Будь-ласка, спробуйте пізніше!',
        }
    };

    function submitUserQuestion() {
        const completeHandler = (xhr) => {
            if (xhr.status == 201) {
                alertify.message(messages[options.locale].success).delay(10);
                submitForm.find('input').val('');
            } else if (xhr.status == 422) {
                formErrorsHandler.setErrors(xhr.responseJSON.errors);
            } else {
                alertify.error(messages[options.locale].error).delay(10);
            }

            setTimeout(() => {
                submitButton.prop('disabled', false);
            }, 1000);
        };

        submitButton.prop('disabled', true);
        $.ajax({
            method: "POST",
            url: options.route,
            headers: {
                'Accept-Language': options.locale
            },
            dataType: "json",
            data: $("#questions-form")
                .serializeArray(),
            complete: completeHandler,
        });
    }

    submitForm.submit((e) => {
        e.preventDefault();
        formErrorsHandler.crearAll();

        submitUserQuestion();
    });
}
